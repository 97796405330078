var blocCarteInteractive = {
    bloc: $(".bloc-carte-interactive"),
    slider: null,
    sliderWrapper: null,
    map: null,
    hotspots: null,
    currentSlide: null,

    init: function () {
        // this.cards = $(".slide", this.bloc);
        this.map = this.bloc.find(".svg-canvas");
        this.hotspots = this.map.find("#ZONE_SVG > #zones > path, #ZONE_SVG > #villes > g");
        this.slides = this.bloc.find(".slide");
        this.initSlider();
        this.goToCard();
    },

    initSlider: function () {

        if (this.bloc) {
            var sliderWrapper = this.bloc.find(".th-slider")[0];
            var _self = this;
            var navButtons = this.bloc.find(".nav-buttons button");


            if (sliderWrapper) {

                this.slider = new thSlider(sliderWrapper, {
                    draggable: true,
                    scrollListener: true,
                    scrollModeMaxWidth: 1199,

                    oninit: function (sliderWrapper) {
                        _self.currentSlide = 0;
                        if (navButtons.length === 2) {
                            thSliderTools.onInitNav(sliderWrapper, navButtons);
                        }

                        // Active le 1er hotspot à l'initialisation
                        _self.highlightHotspots(sliderWrapper.state.current);
                    },
                    beforechange: function (sliderWrapper) {
                        _self.highlightHotspots(sliderWrapper.state.current);

                        if (navButtons.length === 2) {
                            thSliderTools.onChangeNavIgnoreLastItemIn(sliderWrapper, navButtons);
                        }
                    }
                });
            }
        }
    },


    highlightHotspots: function (slideIndex) {
        var _self = this;
        var currentSlide = $(this.slides[slideIndex]);
        var currentArea = $(currentSlide).data('area');

        $.each(this.hotspots, function () {


            if($(this).attr('id') === currentArea ) {
                $(this).addClass("is-active");
            } else {
                $(this).removeClass("is-active");
                $(this).blur();
            }
        });
    },


    goToCard: function () {
        var _self = this;

        $.each(this.hotspots, function (k, v) {
            var id = $(this).attr("id");

            $(this).on("click", function (e) {
                var singleCard = $('.slide[data-area="' + id + '"]');
                _self.slider.goto(singleCard.index());
            });

        });
    },

}

blocCarteInteractive.init();
