var thuriaSearch = {

    scriptLoaded: false,

    client: null,
    index: null,
    language: null,

    input: null,
    searchContainer: null,
    searchScrollContainer: null,
    searchBtnMoreContainer: null,

    callbackUpdate: null,

    lastSearch: 0,

    lastText: null,
    lastResultNbPage: 0,
    lastHitsCountPerIndex: {},
    lastPage: 0,
    lastPostHits: null,
    lastPostHitGenerated: 0,
    intervalGenerateHit: null,
    intervalGeneratePostHit: null,

    lockNextPage: false,
    noresultPhrase: null,

    timeoutInputLimit: null,
    timeoutInputEvent: null,

    init: function (id, key, index, input, searchContainer, searchScrollContainer, searchBtnMoreContainer, callbackUpdate) {

        if (typeof algoliasearch == 'undefined') {
            setTimeout(function () {
                thuriaSearch.init(id, key, index, input, searchContainer, searchScrollContainer, searchBtnMoreContainer);
            }, 500);
            return false;
        }

        thuriaSearch.client = algoliasearch(id, key);
        thuriaSearch.index = index;
        thuriaSearch.input = input;
        thuriaSearch.searchContainer = searchContainer;
        thuriaSearch.searchScrollContainer = searchScrollContainer;
        thuriaSearch.searchBtnMoreContainer = searchBtnMoreContainer;
        thuriaSearch.callbackUpdate = callbackUpdate;
        thuriaSearch.noresultPhrase = thuriaSearch.searchContainer.getAttribute('data-noresult');
        thuriaSearch.initInputEvents();
        thuriaSearch.searchKeywords();
        if (thuriaSearch.searchScrollContainer) {
            thuriaSearch.initScrollEvents();
        }
        if (thuriaSearch.searchBtnMoreContainer) {
            thuriaSearch.initBtnEvents();
        }

        if (wp_debug) {
            //  console.log(thuriaSearch);
        }

        return true;
    },

    setLanguage: function (language) {
        thuriaSearch.language = language;
    },

    initInputEvents: function () {

        thuriaSearch.input.addEventListener('keypress', function (e) {
            if (e.which == 13) {
                e.preventDefault();
            }
        });

        thuriaSearch.input.addEventListener('keydown', function () {
            setTimeout(function () {
                thuriaSearch.search(thuriaSearch.input.value);
            }, 25);
        });

        thuriaSearch.input.addEventListener('keydown', function () {
            clearInterval(thuriaSearch.timeoutInputLimit);
            thuriaSearch.timeoutInputLimit = setTimeout(function () {
                thuriaSearch.search(thuriaSearch.input.value);
            }, 400);
        });
    },

    initScrollEvents: function () {
        thuriaSearch.searchScrollContainer.addEventListener('touchstart', function () {
            if (thuriaSearch.input.id === document.activeElement.id) {
                document.getElementById('search-fake-btn').focus();
            }
        });
        thuriaSearch.searchScrollContainer.addEventListener('scroll', function () {

            if (thuriaSearch.searchScrollContainer.scrollTop > 0) {
                if ((thuriaSearch.searchScrollContainer.scrollHeight - thuriaSearch.searchScrollContainer.scrollTop - thuriaSearch.searchScrollContainer.getBoundingClientRect().height) < 1024) {
                    thuriaSearch.nextPage();
                }
            }
        });
    },
    initBtnEvents: function () {
        thuriaSearch.searchBtnMoreContainer.children[0].addEventListener('touchstart', function (e) {
            e.preventDefault();
            e.stopPropagation();
            thuriaSearch.searchBtnMoreContainer.className += " hide";
            thuriaSearch.nextPage();
        });
        thuriaSearch.searchBtnMoreContainer.children[0].addEventListener('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            thuriaSearch.searchBtnMoreContainer.className += " hide";
            thuriaSearch.nextPage();
        });
    },

    //17302
    searchKeywords: function () {
        var keywords = $(thuriaSearch.searchScrollContainer).find(".keywords-container button");
        keywords.each(function () {
            $(this).on("click", function (e) {
                var chosenKeyWord = $(this).text().replace(/(\r\n|\n|\r)/gm, "").trim()
                thuriaSearch.search(chosenKeyWord);
                $(this).addClass('active');

                Array.from(document.querySelectorAll('.single-search-keyword.active')).forEach(
                    (el) => el.classList.remove('active')
                );

                $(this).addClass('active');
            });
        });
    },



    generateQueries: function (query, checkLastResult) {
        //   console.log(query);
        var finalQueries = [];
        $.each(thuriaSearch.index, function (k, indexName) {
            var isOk = false;
            if (checkLastResult) {
                if (thuriaSearch.lastHitsCountPerIndex[indexName] > 0) {
                    isOk = true;
                }
            }
            if (!checkLastResult || isOk) {
                var q = $.extend(false, query, {indexName: indexName});
                finalQueries.push(q);
            }
        });
        return finalQueries;
    },

    search: function (text) {
        clearTimeout(thuriaSearch.timeoutInputEvent);

        thuriaSearch.lastText = text;
        thuriaSearch.lastSearch++;
        var queryId = thuriaSearch.lastSearch;

        if (thuriaSearch.lastText.length > 2) {
            var q = thuriaSearch.generateQueries({
                query: thuriaSearch.lastText,
                params: {page: 0, hitsPerPage: 12, filters: "taxonomies.language:" + thuriaSearch.language + " AND has_ended:false"}
            });

            thuriaSearch.timeoutInputEvent = setTimeout(function () {
                if(typeof dataLayer != 'undefined') {
                    dataLayer.push({
                        'event': 'sitesearch',
                        "searchTerm": text
                    });
                }
            }, 2000);

            thuriaSearch.client.search(q, function (err, result) {
                if (!err) {
                    var results = result.results;
                    thuriaSearch.handleSearchResult(results, queryId);
                }
            });
        } else {
            thuriaSearch.handleSearchResult({hits: []}, queryId);
        }

    },

    nextPage: function () {
        if (thuriaSearch.lockNextPage == false && thuriaSearch.lastResultNbPage > 1 && thuriaSearch.lastResultNbPage > thuriaSearch.lastPage) {
            thuriaSearch.lockNextPage = true;

            var q = thuriaSearch.generateQueries({
                query: thuriaSearch.lastText,
                params: {page: thuriaSearch.lastPage + 1, hitsPerPage: 12, filters: "taxonomies.language:" + thuriaSearch.language + " AND has_ended:false"}
            }, true);

            thuriaSearch.client.search(q, function (err, result) {
                if (!err) {
                    var results = result.results;
                    var lastPage = null;
                    var lastNbPage = 0;
                    $.each(results, function (k, r) {
                        if (r.nbPages > lastNbPage) {
                            lastNbPage = r.nbPages;
                            lastPage = r.page;
                            thuriaSearch.lastHitsCountPerIndex[r.index] = r.hits.length;
                        }
                    });
                    thuriaSearch.lastPage = lastPage;
                    thuriaSearch.lastResultNbPage = lastNbPage;
                    thuriaSearch.handleResponse(results);
                }
            });
        }
    },

    handleSearchResult: function (results, queryId) {
        if (thuriaSearch.lastSearch == queryId) {
            thuriaSearch.searchContainer.innerHTML = '';
            thuriaSearch.searchContainer.scrollTop = 0;
            thuriaSearch.lockNextPage = false;

            var lastPage = null;
            var lastNbPage = 0;

            $.each(results, function (k, r) {
                if (r.nbPages > lastNbPage) {
                    lastNbPage = r.nbPages;
                    lastPage = r.page;
                }
                if (typeof r.hits != 'undefined') {
                    thuriaSearch.lastHitsCountPerIndex[r.index] = r.hits.length;
                } else {
                    thuriaSearch.lastHitsCountPerIndex[r.index] = 0;
                }
            });

            thuriaSearch.lastPage = lastPage;
            thuriaSearch.lastResultNbPage = lastNbPage;

            if (lastNbPage > 0) {
                thuriaSearch.handleResponse(results);
            } else {
                var hitsLength = '';
                $(".nbHits").text(hitsLength);
                $(".nbHitsPluriel").removeClass("afficher");
                $(".nbHitsSingulier").removeClass("afficher");
                thuriaSearch.searchContainer.innerHTML = '<p role="alert">' + thuriaSearch.noresultPhrase + '</p>';
                if (thuriaSearch.searchBtnMoreContainer && !thuriaSearch.searchBtnMoreContainer.className.match(/hide/)) {
                    thuriaSearch.searchBtnMoreContainer.className += " hide";
                }
            }
        }
    },

    handleResponse: function (results) {
        var html = '';
        var hitsLength = '';
        var nbHits = $(".nbHits");
        var nbHitsSingulier = $(".nbHitsSingulier");
        var nbHitsPluriel =  $(".nbHitsPluriel");

        $.each(results, function (k, result) {
            $.each(result.hits, function (k2, hit) {
                hitsLength ++;
                html += hit.cards_html;
            });
        });

        if(hitsLength > 1) {
            nbHitsPluriel.addClass("afficher");
            nbHitsSingulier.removeClass("afficher");
            nbHits.text(hitsLength);
        }
        else {
            nbHitsSingulier.addClass("afficher");
            nbHitsPluriel.removeClass("afficher");
            nbHits.text(hitsLength);
        }

        thuriaSearch.searchContainer.innerHTML += html;


        //console.log(thuriaSearch.lastResultNbPage,thuriaSearch.lastPage);
        if (thuriaSearch.lastResultNbPage > thuriaSearch.lastPage + 1 && thuriaSearch.searchBtnMoreContainer) {
            thuriaSearch.searchBtnMoreContainer.className = thuriaSearch.searchBtnMoreContainer.className.replace(' hide', '');
        }

        if (thuriaSearch.callbackUpdate) {
            thuriaSearch.callbackUpdate();
        }

        setTimeout(function () {

            var lazyLoadInstance = new LazyLoad({
                elements_selector: ".lazy"
            });
            th_favoris.parseItems();
            th_favoris.addEvents();
            thuriaSearch.lockNextPage = false;

        }, 100);
    },

};

