(function () {
    // si je clique sur le bonton en arrière sur mobile, les input des label villes se décochent
    $('#return-to-city').click(function() {
        $("input[id^=radio-city]").prop('checked', false);
    });


    // on vérifie la première période (nuit, matin, après-midi, soirée) de la météo qui n'est pas vide
    // et on lui ajoute une class pour pouvoir afficher uniquement cette méteo (BIG METEO)
    $('.item-big-meteo').each(function() {
        $(this).find(".day-side").each(function() {
            $(this).find(".day-part").each(function() {
                if(!$(this).is(':empty')) {
                    $(this).addClass("afficher");
                    return false;
                };
            });
        });
    });

    // on boucle sur les éléments day-side et je prends le premier enfant
    // on check la première période (nuit, matin, après-midi, soirée) de la météo qui n'est pas vide
    // et on lui ajoute une class pour pouvoir afficher uniquement cette méteo
    $('.toggle-meteo').each(function() {
        $(this).find(".day-side").each(function(index) {
            if(index == 1) {
                $(this).find(".day-part").each(function(index) {
                    if(! $(this).is(':empty')) {
                        $(this).addClass("afficher");
                        return false;
                    };
                 });
            };
        });
    });

})();