setTimeout(function () {

    var current_lang = document.getElementsByTagName('html')[0].getAttribute('lang');

    if (current_lang) {
        var selectors = document.querySelectorAll('.lang-gtranslate-wrapper select');

        for (var i = 0; i < selectors.length; i++) {
            selectors[i].querySelectorAll('option').forEach(function (option) {
                if (option.value.endsWith(current_lang)) {
                    option.selected = true;
                    $('select').selectric('refresh');       // update de selectric en desktop
                }
            });
        }
    }

}, 1500);