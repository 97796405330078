(function () {
    // clique sur le toogle sur switch
    var link1 = $('.link-1');
    var link2 = $('.link-2');
    var inputSwitch =  $("input[id=switch-search]");
    var link1href = link1.attr('href');
    var link2href = link2.attr('href');

    $('.strate-switch .bouton-switch').click(function () {
        if(inputSwitch.is(':checked')) {
            document.location.href = link1href;
        }
        else {
            document.location.href = link2href;
        }
    });

})();