(function () {


    function TabsThematique(el) {
        if(el) {
            this.el       = el;
            this.btnsTabs = this.el.querySelectorAll('[role="tab"]');
            this.tabPanels = this.el.querySelectorAll('[role="tabpanel"]');
            this.images = this.el.querySelectorAll('.image');
            this.btnPrev = this.el.querySelector('button.--prev');
            this.btnNext = this.el.querySelector('button.--next');

            this.blocId = el.dataset.blocId;        // id unique du bloc pour éviter les conflits avec d'autres blocs
            this.currentId = 1;
            this.lastId = null;
            this.currentImage = this.el.querySelector('.image[data-tab-id="tab-' + this.blocId + '-' + this.currentId + '"]');
            this.lastImage = null;
            this.nbTabs = this.btnsTabs.length;
            this.init();
        }
    }

    TabsThematique.prototype.init = function() {
        // Clic sur les onglets
        this.btnsTabs.forEach(function (btn) {
            btn.addEventListener('click', function (e) {
                this.clickTab(e)
            }.bind(this), false);
        }.bind(this));


        // Clic sur les boutons suivants / précédents
        if(this.btnPrev) {
            this.btnPrev.addEventListener('click', function (e) {
                this.jumpToTab('prev');
            }.bind(this), false);
        }

        if(this.btnNext) {
            this.btnNext.addEventListener('click', function (e) {
                this.jumpToTab('next');
            }.bind(this), false);
        }
    };


    // Onglet précédent / suivant avec les boutons flèches
    TabsThematique.prototype.jumpToTab = function(dir) {
        (dir === 'next') ? this.updateView(this.currentId + 1) : this.updateView(this.currentId - 1);
    };


    // Clique sur un onglet et update de la view
    TabsThematique.prototype.clickTab = function(e) {
        var target = e.currentTarget;
        var id = target.id;
        id = Number(id.substr(id.lastIndexOf('-')+1));      // récupère l'id : "tab-1455804060-1" -> "1"
        if (id !== this.currentId) this.updateView(id);


        /*
        e.preventDefault();
        // target.scrollIntoView({inline: 'start', block: 'nearest', behavior: 'smooth' });
        console.log('bla');

            // setTimeout(function () {
                target.scrollIntoView({inline: 'start', block: 'start', behavior: 'smooth' });
            // }, 1000);
        */
    };



    // Actualisation des tabs, textes et images quand changement d'id
    TabsThematique.prototype.updateView = function(id) {
        this.lastId = this.currentId;
        this.currentId = id;

        // *** tabs buttons ***
        // Remove all current selected tabs
        this.btnsTabs.forEach(function (btn) {
            btn.setAttribute('aria-selected', false);
        });
        // Set this tab as selected
        var currentBtn = this.el.querySelector('.tablist__btn[id="tab-' + this.blocId + '-' + this.currentId + '"]');
        currentBtn.setAttribute('aria-selected', true);

        // *** tabs panels ***
        // Hide all tab panels
        this.tabPanels.forEach(function (panel) {
            panel.setAttribute('hidden', '');
        });
        // Show the selected panel
        var currentPanel = this.el.querySelector('#' + currentBtn.getAttribute("aria-controls"));
        if(currentPanel) {
            currentPanel.removeAttribute('hidden');
        }

        // *** images ***
        this.lastImage = this.currentImage;
        this.currentImage = this.el.querySelector('.image[data-tab-id="tab-' + this.blocId + '-' + this.currentId + '"]');

        // console.log('currentId', this.currentId, '('+this.lastId+')');
        // console.log('currentImage', this.currentImage, '\n(',this.lastImage,')');

        // direction de la transition d'entrée de la nouvelle image
        var dir = (this.currentId > this.lastId) ? 'right' : 'left';

        this.lastImage.classList.remove('is-selected');
        this.lastImage.classList.remove('trans-in-left');
        this.lastImage.classList.remove('trans-in-right');
        this.lastImage.classList.add('trans-out');

        this.currentImage.classList.add('is-selected');
        this.currentImage.classList.add('trans-in-' + dir);

        if(this.btnPrev && this.btnNext) this.updateControls();
    };


    // Affiche / masque les boutons suivant / précédent en fonction de l'id courant
    TabsThematique.prototype.updateControls = function () {

        if(this.currentId === 1) {
            this.btnPrev.setAttribute('disabled', true);
            this.btnNext.removeAttribute('disabled');       // <-- remove disabled from the next
        } else if(this.currentId === this.nbTabs) {
            this.btnNext.setAttribute('disabled', true);
            this.btnPrev.removeAttribute('disabled');       // <-- remove disabled from the prev
        } else {
            this.btnPrev.removeAttribute('disabled');
            this.btnNext.removeAttribute('disabled');
        }
    };


    // Initialisation sur tous les blocs concernés de la page
    var blocs = document.querySelectorAll('.bloc-onglets-thematique');
    blocs.forEach(function (el) {
        var bloc = new TabsThematique(el);
    });


})();


/*
var sliderContainers = document.querySelectorAll(".zbloc-onglets-thematique");
console.log(sliderContainers);
for (i = 0; i != sliderContainers.length; i++) {
    console.log(i);
    var sliderContainer = sliderContainers[i];
    initSliderThematique(sliderContainer);
}

function initSliderThematique(sliderContainer) {
    if (sliderContainer) {
        var navButtons = sliderContainer.querySelectorAll(".nav-buttons button");
        var customLinksWrapper = sliderContainer.querySelector('.nav-links');
        var slider = sliderContainer.querySelector(".th-slider");

        this.slider = new thSlider(slider, {
            scrollListener: true,
            draggable: true,
            scrollModeMaxWidth: 1199,
            oninit: function (slider) {

                if (navButtons.length === 2) {
                    thSliderTools.onInitNav(slider, navButtons);
                }

                var customLinks = customLinksWrapper.querySelectorAll('button');

                for (var i = 0; i < customLinks.length; i++) {
                    customLinks[i].addEventListener('click', function () {
                        slider.goto(parseInt(this.getAttribute('data-index')));
                    });
                }
            },
            onchange: function (slider) {

                if (navButtons.length > 0) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons);
                }
                if (customLinksWrapper) {
                    thSliderTools.onChangeDots(slider, customLinksWrapper);
                }

            }
        });
    }
}*/
