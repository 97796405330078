// fonction pour afficher les informations de contact en fonction de ce qui a été choisi dans le select
(function () {
    var selectedOption = $("#selectOffice");
    var itemContact = $(".item-contact");
    var selectedOptionval = selectedOption.val();

    // récupérer la valeur dans le select
    selectedOption.on('change', function() {
        selectedOptionval = selectedOption.val();
        showContact();
    });

    // cache tous les item-contact et affiche celui qui a le même id que la valeur du select
    function showContact () {
        itemContact.each(function() {
            itemContactID = $(this).attr('id');

            if(selectedOptionval === itemContactID) {
                $(".item-contact").hide();
                $(this).show();
            }
        });
    }

    showContact ();

})();