th_maps.onLoad(function () {

    th_maps.addMarkerIcon('default', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/gavarnie/assets/images/markers/ico-marker-map-2x.png',
        shadowUrl: null,
        iconSize: [60, 60],
        iconAnchor: [30, 30]
    });

    th_maps.addMarkerIcon('hover', {
        iconUrl: '' + document.location.origin + '/wp-content/themes/gavarnie/assets/images/markers/ico-marker-map-hover-2x.png',
        shadowUrl: null,
        iconSize: [60, 60],
        iconAnchor: [30, 30]
    });

});

th_maps.init(thConfig.map.init);