(function(){

    var sliders = document.querySelectorAll('.cover-home .th-slider');
    for (i = 0; i != sliders.length; i++) {
        var slider = sliders[i];
        initSlider(slider);
    }

    function initSlider(slider) {
        var parent = slider.parentNode;
        while (!parent.className.match(/th-slider-wrapper/)) {
            parent = parent.parentNode;
        }
        var navButtons = document.querySelector('.cover-home .nav-buttons');
        var dotsWrapper = document.querySelector('.cover-home .nav-dots-2');

        var s = new thSlider(slider, {
            draggable: true,
            scrollListener: true,

            oninit: function (slider) {
                if (navButtons) {
                    thSliderTools.onInitNav(slider, navButtons.children);
                }

                // Ajout des numéros sur les dots
                if (dotsWrapper) {
                    thSliderTools.onInitDots(slider, dotsWrapper, function (dotEl, index) {
                        if (parseInt(index) < 9) {
                            dotEl.innerHTML = '0' + (parseInt(index) + 1);
                        } else {
                            dotEl.innerHTML = (parseInt(index) + 1);
                        }
                        return dotEl;
                    });
                }

                if (navButtons) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons.children);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper);
                }
            },
            beforechange: function (slider) {
                if (navButtons) {
                    thSliderTools.onChangeNavIgnoreLastItemIn(slider, navButtons.children);
                }
                if (dotsWrapper) {
                    thSliderTools.onChangeDots(slider, dotsWrapper);
                }
            }
        });
    }

})();
